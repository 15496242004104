import React from 'react'

function MyMap() {
  return (
    <div sx="width: 100%">
      <iframe title='gMap' width="100%" height="850" src="https://maps.google.com/maps?width=100%25&amp;height=850&amp;hl=en&amp;q=FudoShinkan+(Fudo%20Shinkan%20%20Kendo%20Club)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe>
    </div>
  )
}

export default MyMap